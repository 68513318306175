import React, { useEffect, useRef, useState } from 'react'

import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { ImPlus } from 'react-icons/im';
import { Button } from 'react-bootstrap';
import TopEmpList from './topEmpList/TopEmpList';
import ComplaintForm from './complaintAddForm/ComplaintForm';
import { base_Url_cmms } from '../../../server';
import DashboardDetails from './complaintDashboardDetails/DashboardDetails';


function ComplaintsDashboard() {
    let inputRef = useRef(null)
    const navigate = useNavigate()

    const [show, setShow] = useState(false);
    const [complaintListData, setComplaintListData] = useState(null)
    const [searchcomplaintListData, setSearchComplaintListData] = useState(null)

    const [searchBtnDisabled, setSearchBtnDisabled] = useState(true)

    // console.log(complaintListData)
    // const getPendingData = async () => {
    //     const pendingList_response = await axios.get(`${base_Url_cmms}ccm_dashboard/admin`)
    //     setComplaintListData(pendingList_response.data)
    // }



    const getTopEmployeeListData = async () => {
        const response = await axios.get(`${base_Url_cmms}ccm_dashboard/admin`)
        setComplaintListData(response.data)
    }

    const handleSearch = async () => {

        try {
            const response = await axios.get(`${base_Url_cmms}ticket_ccm/search/${inputRef.current.value}`)
            const searchData = response.data
            setSearchComplaintListData(searchData)
        } catch (error) {
            console.log(`the shoib's code error : ${error}`);
        }
        setComplaintListData("")
        setSearchBtnDisabled(false)
    }
    const getDetails = (_id) => {
        /* if (prams.id === _id) {
            navigate( )
        } */
    }

    const accesSearch = () => {
        setSearchBtnDisabled(false)
    }


    /* const changeRoute = (str) => {
        if (str === 'customer-list') {
            navigate('customer-list')
        }
        if (str === 'all_orders') {
            navigate('all_orders')
        }
        if (str === 'categories') {
            navigate('categories')
        }
        if (str === 'products/all') {
            navigate('products/all')
        }
        if (str === 'seller') {
            navigate('seller')
        }
        if (str === 'pick_up_points') {
            navigate('pick_up_points')
        }
        if (
            str === 'add-newasset-category'
        ) {
            navigate('add-newasset-category')
        }
    } */


    const resetData = () => {
        // getPendingData()
        getTopEmployeeListData()
        setSearchComplaintListData("")
    }
    useEffect(() => {
        // getPendingData()
        getTopEmployeeListData()

    }, [])

    return <>
        <div className="aiz-main-content">
            <div className="px-15px px-lg-25px">

                <div className="row gutters-10">
                    <div className="col-lg-12">
                        <div className="row gutters-10">

                            <div className="col-md-3 col-sm-6 col-xs-12  ">
                                <div style={{ width: "100%", cursor: "pointer" }} /* onClick={() => changeRoute('all_orders')} */ className="bg-grad-2 text-white rounded-lg mb-4 overflow-hidden d-flex py-4 align-items-center justify-content-between  pr-4  ">
                                    {/*                                     <div className="">
                                        <div className="">
                                             <img src={assetIcon1} alt="k" /> 
                                        </div>
                                    </div> */}
                                    <div className="px-3  ">
                                        <div className="">
                                            <span className="fs-18 fw-bold d-block"> Complaints
                                            </span>
                                            <span className="fs-18 fw-bold d-block"> Registered Today
                                            </span>
                                        </div>
                                    </div>
                                    <div className="h3 fw-700 mb-3">
                                        <strong>10</strong>
                                    </div>

                                </div>
                            </div>


                            <div className="col-md-3 col-sm-6 col-xs-12  ">
                                <div style={{ width: "100%", cursor: "pointer" }} /* onClick={() => changeRoute('all_orders')} */ className="bg-grad-3 text-white rounded-lg mb-4 overflow-hidden d-flex py-4 align-items-center justify-content-between  pr-4">

                                    <div className="px-3  ">
                                        <div className="">
                                            <span className="fs-18 fw-bold d-block"> All</span>
                                            <span className="fs-18 fw-bold d-block"> Complaints </span>
                                        </div>
                                    </div>
                                    <div className="h3 fw-700 mb-3">
                                        <strong>32</strong>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-xs-12  ">
                                <div style={{ width: "100%", cursor: "pointer" }} /* onClick={() => changeRoute('all_orders')} */ className="bg-grad-3 text-white rounded-lg mb-4 overflow-hidden d-flex py-4 align-items-center justify-content-between  pr-4">
                                    {/* <div className="">
                                        <div className="">
                                             <img src={assetIcon1} alt="k" /> 
                                        </div>
                                    </div> */}
                                    <div className="px-3  ">
                                        <div className="">
                                            <span className="fs-18 fw-bold d-block">Complaints
                                            </span>
                                            <span className="fs-18 fw-bold d-block">Pending
                                            </span>
                                        </div>
                                    </div>
                                    <div className="h3 fw-700 mb-3">
                                        <strong>23</strong>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-xs-12  ">
                                <div style={{ width: "100%", cursor: "pointer" }} /* onClick={() => changeRoute('all_orders')} */ className="bg-grad-3 text-white rounded-lg mb-4 overflow-hidden d-flex py-4 align-items-center justify-content-between  pr-4">
                                    {/* <div className="">
                                        <div className="">
                                             <img src={assetIcon1} alt="k" /> 
                                        </div>
                                    </div> */}
                                    <div className="px-3  ">
                                        <div className="">
                                            <span className="fs-18 fw-bold d-block">Complaints
                                            </span>
                                            <span className="fs-18 fw-bold d-block">Closed
                                            </span>
                                        </div>
                                    </div>
                                    <div className="h3 fw-700 mb-3">
                                        <strong>42</strong>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-xs-12  ">
                                <div style={{ width: "100%", cursor: "pointer" }} /* onClick={() => changeRoute('all_orders')} */ className="bg-grad-1 text-white rounded-lg mb-4 overflow-hidden d-flex py-4 align-items-center justify-content-between  pr-4">
                                    {/* <div className="">
                                        <div className="">
                                             <img src={assetIcon1} alt="k" /> 
                                        </div>
                                    </div> */}
                                    <div className="px-3  ">
                                        <div className="">
                                            <span className="fs-18 fw-bold d-block">Complaints
                                            </span>
                                            <span className="fs-18 fw-bold d-block">Open
                                            </span>
                                        </div>
                                    </div>
                                    <div className="h3 fw-700 mb-3">
                                        <strong>12</strong>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-3 col-sm-6 col-xs-12  ">
                                <div style={{ width: "100%", cursor: "pointer" }} /* onClick={() => changeRoute('all_orders')} */ className="bg-grad-4 text-white rounded-lg mb-4 overflow-hidden d-flex py-4 align-items-center justify-content-between  pr-4">
                                    {/* <div className="">
                                        <div className="">
                                             <img src={assetIcon1} alt="k" /> 
                                        </div>
                                    </div> */}
                                    <div className="px-3  ">
                                        <div className="">
                                            <span className="fs-18 fw-bold d-block">Complaints
                                            </span>
                                            <span className="fs-18 fw-bold d-block">Assigned
                                            </span>
                                        </div>
                                    </div>
                                    <div className="h3 fw-700 mb-3">
                                        <strong>16</strong>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-3 col-sm-6 col-xs-12  ">
                                <div style={{ width: "100%", cursor: "pointer" }} /* onClick={() => changeRoute('all_orders')} */ className="bg-grad-4 text-white rounded-lg mb-4 overflow-hidden d-flex py-4 align-items-center justify-content-between  pr-4">
                                    {/* <div className="">
                                        <div className="">
                                             <img src={assetIcon1} alt="k" /> 
                                        </div>
                                    </div> */}
                                    <div className="px-3  ">
                                        <div className="">
                                            <span className="fs-18 fw-bold d-block">Complaints
                                            </span>
                                            <span className="fs-18 fw-bold d-block">Under Processing
                                            </span>
                                        </div>
                                    </div>
                                    <div className="h3 fw-700 mb-3">
                                        <strong>50</strong>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-xs-12  ">
                                <div style={{ width: "100%", cursor: "pointer" }} /* onClick={() => changeRoute('all_orders')} */ className="bg-grad-2 text-white rounded-lg mb-4 overflow-hidden d-flex py-4 align-items-center justify-content-between  pr-4">
                                    {/* <div className="">
                                        <div className="">
                                             <img src={assetIcon1} alt="k" /> 
                                        </div>
                                    </div> */}
                                    <div className="px-3  ">
                                        <div className="">
                                            <span className="fs-18 fw-bold fw-bold d-block">Complaints
                                            </span>
                                            <span className="fs-18 fw-bold fw-bold d-block">Holds
                                            </span>
                                        </div>
                                    </div>
                                    <div className="h3 fw-700 mb-3">
                                        <strong>12</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    show && <ComplaintForm show={show} setShow={setShow} />
                }

                <div className='row  mb-3 justify-content-end'>

                    <div className="col-lg-4 col-md-6 col-sm-10">
                        <div className='d-flex'>
                            <input type="text" ref={inputRef} className="form-control" id="search" aria-describedby="emailHelp" placeholder='Search by ID' onKeyDown={accesSearch} />
                            <Button disabled={searchBtnDisabled} className='ms-2' variant='success' onClick={handleSearch} >Search</Button>
                            <Button disabled={searchBtnDisabled} className='ms-2' variant='info' onClick={resetData} >reset</Button>
                        </div>
                    </div >

                    <div className='col-lg-4 col-md-6 col-sm-2 text-end'>
                        <Button onClick={() => setShow(true)}> <ImPlus /></Button>
                    </div>
                </div>
                {searchcomplaintListData &&
                    <DashboardDetails searchListData={searchcomplaintListData} getDetails={getDetails} />
                }
                {complaintListData && <div>
                    <div className="col-lg-8 col-md-6 col-sm-12">
                        <h5><strong>Top 10 Pending Complaint List
                        </strong></h5>
                    </div>
                    <div>
                        <TopEmpList pendingList={complaintListData.TopPendingtickets} getDetails={getDetails} />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h5><strong>Top 10 Employee's Office wise Complaint List
                        </strong></h5>
                    </div>
                    <TopEmpList pendingList={complaintListData.officeList} getDetails={getDetails} />
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h5><strong>Top 10 Employee's Complaint List</strong></h5>
                    </div>
                    <div>
                        <TopEmpList pendingList={complaintListData.TopEmployeeList} getDetails={getDetails} />
                    </div>
                </div>}


            </div>
        </div >

    </>
}

export default ComplaintsDashboard
