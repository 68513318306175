import React from 'react'
// import EtsyAccount from '../../components/etsyAccountList/etsyAccount'
import EtsyAccount from '../../Components/etsyAccountList/EtsyAccount';

function EtsyAccList() {
    return (
        <>
            <EtsyAccount />
        </>
    )
}
export default EtsyAccList