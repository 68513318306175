

import AmazonAccountLists from "../../Components/amazon-account/amazonAccountList/AmazonAccountLists";


function AmazonAccountListPage() {
  return (
    <>
      <AmazonAccountLists />
    </>
  );
}
export default AmazonAccountListPage;
