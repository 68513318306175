// import NewAmazonAccount from "../../components/amazon-account/new-amazon-account/NewAmazonAccount";
import NewAmazonAccount from "../../Components/amazon-account/new-amazon-account/NewAmazonAccount";

function NewAmazonAccountPage() {
  return (
    <>
      <NewAmazonAccount />
    </>
  );
}
export default NewAmazonAccountPage;
