// import CategoryMap from "../../components/amazon-account/categoryMap/CategoryMap";
import CategoryMap from "../../Components/amazon-account/categoryMap/CategoryMap";

function CategoryMapPage() {
  return (
    <>
      <CategoryMap />
    </>
  );
}
export default CategoryMapPage;
