// import PricingRules from "../../components/amazon-account/pricingRules/PricingRules";
import PricingRules from "../../Components/amazon-account/pricingRules/PricingRules";

function PricingRulesPage() {
  return (
    <>
      <PricingRules />
    </>
  );
}
export default PricingRulesPage;
